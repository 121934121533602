<template>
    <PageLoader>
        <section>
            <!-- <Table :columns="tableData" :data="products" /> -->

            <table class="min-w-full divide-y divide-fibonaki-border">
                <thead>
                    <tr>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('id', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('name', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-center text-sm font-medium text-gray-900">{{ $tc('status', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('valid_from', 1) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('valid_till', 1) }}</th>
                        <th scope="col">
                            <span class="sr-only">{{ $tc('menu') }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in products" :key="product">
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ product.id }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ product.name }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 text-center">
                            <Badge :color="statusColor(product.status)">{{ product.status }}</Badge>
                        </td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ product.valid_from }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ product.valid_till }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm">
                            <Menu as="div" class="relative flex justify-end items-center">
                                <MenuButton class="w-8 h-8 bg-white border inline-flex items-center justify-center text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    <span class="sr-only">{{ $tc('open_option', 2) }}</span>
                                    <DotsHorizontalIcon class="w-5 h-5" aria-hidden="true" />
                                </MenuButton>
                                <!-- <MenuItems class="origin-top-right absolute right-0 top-9 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                        <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-3 text-sm']">Activate product</button>
                                    </MenuItem>

                                    <MenuItem v-slot="{ active }">
                                        <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-3 text-sm']">Extend product</button>
                                    </MenuItem>
                                </div>
                            </MenuItems> -->
                                <MenuItems class="origin-top-right absolute right-0 top-9 mt-1 w-48 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                    <div class="py-1">
                                        <MenuItem v-slot="{ active }">
                                            <button @click="openActivateModal(product)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-3 text-sm']">
                                                <ClipboardCheckIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span>Activate product</span>
                                            </button>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-3 text-sm']">
                                                <ClockIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span>Extend product</span>
                                            </a>
                                        </MenuItem>
                                    </div>
                                    <div class="py-1">
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" :class="[active ? 'bg-red-100 text-red-900' : 'text-red-700', 'flex px-4 py-3 text-sm']">
                                                <TrashIcon class="mr-3 h-5 w-5 text-red-400" aria-hidden="true" />
                                                <span>Remove product</span>
                                            </a>
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </Menu>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>

        <Modal :title="`${$tc('activate_product', 1)} : ${selectedProduct.name}`" :show="openActivateProductModal" @submit="activateProduct" @close="closeActivateModal">
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-span-12">
                    <Input :label="$tc('name', 1)" type="date" id="valid_from" v-model="product.valid_from" />
                    <Input :label="$tc('name', 1)" type="date" id="valid_till" v-model="product.valid_till" />
                </div>
            </div>
        </Modal>
    </PageLoader>
</template>

<script>
    import Badge from '@/components/general/Badge.vue';
    import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
    import { DotsHorizontalIcon } from '@heroicons/vue/solid';
    import { ClipboardCheckIcon, ClockIcon, TrashIcon } from '@heroicons/vue/outline';

    export default {
        components: {
            Badge,
            Menu,
            MenuButton,
            MenuItems,
            MenuItem,
            DotsHorizontalIcon,
            ClipboardCheckIcon,
            ClockIcon,
            TrashIcon,
        },
        data() {
            return {
                selectedProduct: {
                    id: null,
                    name: null,
                },
                openActivateProductModal: false,
                openExtendProductModal: false,
                openRemoveProductModal: false,
                product: {},
                tableHeaders: [
                    { display_name: this.$tc('id', 1), name: 'id' },
                    { display_name: this.$tc('name', 1), name: 'display_name' },
                    { display_name: this.$tc('status', 1), name: 'status' },
                    { display_name: this.$tc('valid_from', 1), name: 'valid_from' },
                    { display_name: this.$tc('valid_till', 1), name: 'valid_till' },
                ],
            };
        },

        created() {
            this.$store.dispatch('clients/fetchProducts', this.$route.params.client_id);
        },

        computed: {
            products() {
                return this.$store.getters['clients/products'].map((product) => {
                    return {
                        id: product.id,
                        name: product.display_name,
                        status: product.status,
                        valid_from: product.valid_from || '-',
                        valid_till: product.valid_till || '-',
                    };
                });
            },
            statusColor() {
                return (status) => {
                    switch (status) {
                        case 'inactive':
                            return 'gray';
                        case 'active':
                            return 'green';
                        case 'expired':
                            return 'red';
                        case 'added':
                            return 'blue';
                        default:
                            return 'gray';
                    }
                };
            },
        },
        methods: {
            openActivateModal(product) {
                console.log(this.selectedProduct);
                this.selectedProduct.id = product.id;
                console.log(this.selectedProduct.id);
                this.selectedProduct.name = product.name;
                console.log(this.selectedProduct.name);
                this.openActivateProductModal = true;
            },
            closeActivateModal() {
                this.selectedProductId = null;
                this.openActivateProductModal = false;
            },
            async activateProduct() {
                await this.$store.dispatch('clients/activateProduct', { client_id: this.$route.params.client_id, product: this.product, product_id: this.selectedProduct.id });
                this.closeActivateModal();
                this.product = {};
                console.log('activate product');
            },
            extendProduct() {
                console.log('extend product');
            },
            removeProduct() {
                console.log('remove product');
            },
        },
    };
</script>
